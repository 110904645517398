import authMiddleware  from '@/middleware/auth.middleware'
export default [
  {
    path: '/asegurados/form/:id?',
    name: 'asegurado.form',
    component: ()=>import('../asegurado/form/index.vue'),
    beforeEnter: [authMiddleware]
  },
  {
    path: '/asegurados',
    name: 'asegurado.listar',
    component: ()=>import('../asegurado/listar/index.vue'),
    beforeEnter: [authMiddleware],
  }
]