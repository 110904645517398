export default [
  {
    path: '/aseguradoras/form/:id?',
    name: 'aseguradora.form',
    component: ()=>import('../aseguradora/form/index.vue')
  },
  {
    path: '/aseguradoras',
    name: 'aseguradora.listar',
    component: ()=>import('../aseguradora/listar/index.vue')
  }
]