import { createRouter, createWebHistory } from 'vue-router'
import Auth from '../views/auth/routes'
import checkAuth from '../middleware/checkAuth.middleware'
import authMiddleware from '@/middleware/auth.middleware'

import empresaRoutes from '../views/empresa/routes'
import aseguradoRoutes from '../views/asegurado/routes'
import polizaRoutes from '../views/poliza/routes'
import aseguradoraRoutes from '../views/aseguradora/routes'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=>import('../views/Home.vue'),
    beforeEnter: [authMiddleware],
  },
  {
    path: '/403',
    name: 'noAutorizado',
    component: ()=>import('../views/errors/403/index.vue'),
    beforeEnter: [authMiddleware],
  },
  ...Auth,
  ...empresaRoutes,
  ...polizaRoutes,
  ...aseguradoRoutes,
  ...aseguradoraRoutes,
  { 
    path: "/:pathMatch(.*)*", 
    component: ()=>import('../views/errors/404/index.vue') 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(checkAuth)

router.afterEach((to)=>{
  const {title} = to.meta
  document.title = `${title|| 'INICIO'} | MASIVOS`
})

export default router
