export default [
  {
    path: '/empresa/polizas/form/:polizaId?',
    name: 'poliza.form',
    component: () => import('./form/index.vue')
  },
  {
    path: '/empresa/:empresaId/polizas',
    name: 'poliza.listar',
    component: () => import('./listar/index.vue')
  }
]