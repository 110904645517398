import Cookies from 'js-cookie'
import authMeService from '@/views/auth/services/authMe.service'
// import authLogoutService from '@/views/auth/services/authLogout.service'

const state = () => {
  return {
    user: null,
    token: Cookies.get('token'),
  }
}

// getters
const getters = {
  token: state => state.token,
  user: state => state.user,
  check: state => state.user !== null
}

// actions
const actions = {
  saveToken ({ commit}, payload) {
    commit('SAVE_TOKEN', payload)
  },
  async fetchUser ({ commit }) {
    try {
      const { data } = await authMeService()
      commit('FETCH_USER_SUCCESS', { user: data })
    } catch (e) {
      commit('FETCH_USER_FAILURE')
    }
  },
  async logout ({ commit }) {
    commit('LOGOUT')
  },  
}

// mutations
const mutations = {
  SAVE_TOKEN(state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null })
  },
  LOGOUT (state) {
    state.user = null
    state.token = null

    Cookies.remove('token')
  },
  FETCH_USER_SUCCESS(state, { user }) {
    let userData = user
    state.user = userData
  },
  FETCH_USER_FAILURE (state) {
    state.token = null
    Cookies.remove('token')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}