<template>
  <router-view />
</template>
<script>
export default {
  setup() {
    
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

#app {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #2c3e50;
  font-size: 100%;

  h1,h2,h3,h4{
    font-weight: bold;
    color: #46719C;
  }
  h1{
    font-size: 2em;
  }
  h2{
    font-size: 1.5em;
  }
  h3{
    font-size: 1em;
  }
  h4{
    font-size: 0.5em;
  }

  p{
    font-size: 0.885em;
  }
}
.swal2-container{
  z-index: 20000 !important;
}
</style>
