import authMiddleware from '@/middleware/auth.middleware'
import soloAdmin from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/empresas/form/:id?',
    name: 'empresa.form',
    component: ()=>import('./form/index.vue'),
    beforeEnter: [authMiddleware,soloAdmin]
  },
  {
    path: '/empresas',
    name: 'empresa.listar',
    component: ()=>import('./listar/index.vue'),
    beforeEnter: [authMiddleware,soloAdmin]
  }
]